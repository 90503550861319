import { apiPost, apiGet } from '../utils/http';

// 启动砍价
export async function launch(data = {}) {
    const api = '/book/activity/bargain/launch';
    return await apiPost(api, data);
}

// 获取汽车详情
export async function getConfig(data = {}) {
    const api = '/book/activity/bargain/getConfig';
    return await apiPost(api, data);
}

// 获取活动配置详情
export async function fetchCouponTopicInfo(params = {}) {
    const api = '/book/activity/getCouponTopicInfo';
    return await apiGet(api, params);
}
// 配置或动领券
export async function fetchCouponTopicPlay(params = {}) {
    const api = '/book/activity/getCouponTopicPlay';
    return await apiGet(api, params);
}

// ------ 新版物料后台

// 获取活动券信息
export async function fetchNewCouponTopicInfo(params = {}) {
    const api = '/book/activity/getNewCouponTopicInfo';
    return await apiGet(api, params);
}
// 新版物料后台配置或动领券
export async function fetchNewCouponTopicPlay(params = {}) {
    const api = '/book/activity/getNewCouponTopicPlay';
    return await apiGet(api, params);
}